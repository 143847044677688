import React, { ChangeEvent, FormEvent, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaClock,
} from "react-icons/fa"; // İkonlar eklendi
import "./Contact.css";

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const { name, email, subject, message } = formData;

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <section className="contact-page-sec">
      <div className="container ">
        <div className="row">
          <div className="col-md-4">
            <div className="contact-info">
            <div className="contact-info-item">
  <div className="contact-info-icon">
    <FaMapMarkerAlt />
  </div>
  <div className="contact-info-text">
    <h2>address</h2>
    <span>Gaziler Mahallesi 251 Sokak No:1 M</span>
    <span>Kepez/Antalya</span>
  </div>
</div>

            </div>
          </div>
          <div className="col-md-4">
            <div className="contact-info">
              <div className="contact-info-item ">
                <div className="contact-info-icon">
                  <FaEnvelope /> {/* E-mail ikonu */}
                </div>
                <div className="contact-info-text">
                  <h2>E-mail</h2>
                  <span></span> 
                  <span>coffeedean@coffeedean.com.tr</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="contact-info">
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <FaClock /> {/* Saat ikonu */}
                </div>
                <div className="contact-info-text">
                  <h2>Çalışma Saatlerimiz</h2>
                  <span>PZT - CMT 9:00 - 6.30</span>
                  <span>Pazar: Kapalı</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-md-8">
            <div className="contact-page-form">
              <h2 className="text-altin">Bizimle iletişime geçin</h2>
              <form action="contact-mail.php" method="post">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="single-input-field">
                      <input type="text" placeholder="Your Name" name="name" />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="single-input-field">
                      <input
                        type="email"
                        placeholder="E-mail"
                        name="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="single-input-field">
                      <input
                        type="text"
                        placeholder="Phone Number"
                        name="phone"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="single-input-field">
                      <input type="text" placeholder="Subject" name="subject" />
                    </div>
                  </div>
                  <div className="col-md-12 message-input">
                    <div className="single-input-field">
                      <textarea
                        placeholder="Write Your Message"
                        name="message"
                      ></textarea>
                    </div>
                  </div>
                  <div className="single-input-fieldsbtn">
                    <input type="submit" value="Send Now" />
                  </div>
                </div>
              </form>
            </div>
          </div> */}
          {/* <div className="col-md-12">
            <div className="contact-page-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d15416.056945056143!2d30.682502339261113!3d36.91013480997032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x14c38fb057454f03%3A0x27cbaa8d5bc0a46!2sY%C3%BCkseli%C5%9F%2C%20Kocatepe%20Cd.%20No%3A18%20D%3A1A%2C%2007025%20Kepez%2FAntalya!3m2!1d36.910578099999995!2d30.6915813!5e0!3m2!1str!2str!4v1704726568009!5m2!1str!2str"
                width="100%"
                height="auto"
                style={{ border: "0" }}
                loading="lazy"
              ></iframe>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Contact;
