import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaTools,
  FaTruck,
  FaHeadset,
  FaPhone,
  FaMailBulk,
  FaLocationArrow,
  FaMapMarker,
  FaRegClock,
} from "react-icons/fa";
import "./Footer.css"; // Make sure this CSS file is updated
import logo from "../Image/logo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const goToHakkimizda = () => {
    navigate("/Kurumsal");
  };
  const goToUrunler = () => {
    navigate("/urunler");
  };
  const goToIletisim = () => {
    navigate("/contact");
  };
  const goToAnasayfa = () => {
    navigate("/home");
  };
  return (
    // <footer className="bg-dark text-light py-4">
    //   <Container fluid className="p-5">
    //     <Row>
    //       <Col md={3}>
    //         <div className="d-flex justify-content-center mb-4">
    //           <img style={{ height: "140px" }} src={logo} alt="logo" />
    //         </div>
    //         <div className="social-icons d-flex justify-content-center">
    //           {[FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn].map(
    //             (Icon, index) => (
    //               <div key={index} className="icon-circle">
    //                 <Icon />
    //               </div>
    //             )
    //           )}
    //         </div>
    //       </Col>
    //       <Col md={3}>
    //         <h5 className="text-center">HİZMETLERİMİZ</h5>
    //         <ul className="list-unstyled">
    //           <li className="text-center">
    //             <FaTools color="#DAA520" /> 724 Teknik Servis Desteği
    //           </li>
    //           <li className="text-center">
    //             <FaTruck color="#DAA520" /> Konsinye Makine
    //           </li>
    //         </ul>
    //       </Col>
    //       <Col md={3} className="text-center">
    //         <h5 className="text-center">İLETİŞİM BİLGİLERİ</h5>
    //         <p className="text-center">
    //           <FaMailBulk color="#DAA520" /> coffeedean@coffeedean.com.tr
    //         </p>
    //         <p className="text-center">
    //           <FaPhone color="#DAA520" /> 0212 000 00 00
    //         </p>
    //         <p className="text-center">
    //           <FaMapMarker color="#DAA520" />
    //           Yükseliş, Kocatepe Cd. No:18 D:1A, 07025 Kepez/Antalya
    //         </p>
    //       </Col>
    //       <Col md={3} className="text-center">
    //         <h5><FaRegClock color="#DAA520" /><span className="ms-3">ÇALIŞMA SAATLERİ</span></h5>
    //         <p className="dashed">Hafta İçi: 09:00 - 18:00</p>
    //         <p className="dashed">Cumartesi: 09:00 - 18:00</p>
    //         <p className="dashed">Pazar: Kapalı</p>
    //       </Col>
    //     </Row>
    //     <Row>
    //       <Col>
    //         <p className="text-center mt-3">COPYRIGHT © CoffeeDean</p>
    //       </Col>
    //     </Row>
    //   </Container>
    // </footer>
    <footer>
      <div className="top_header">
        <section>
          <span>
            <i className="fa fa-map-marker"></i>
          </span>
          <span> Gaziler Mahallesi 251 Sokak No:1 M KEPEZ/ANTALYA</span>
        </section>
        <section style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://wa.me/905514022172", "_blank");
          }}
        >
          <span>
            <i className="fa fa-phone"></i>
          </span>
          <span> 0 551 402 21 72</span>
        </section>
        <section>
          <span>
            <i className="fa fa-envelope"></i>
          </span>
          <span>coffeedean@coffeedean.com.tr</span>
        </section>
      </div>
      <span className="border-shape"></span>
      <div className="bottom_content">
        <section>
          <a href="https://www.instagram.com/coffeedean.tr/" target="_blank">
            <i className="fa fa-instagram"></i>
          </a>
        </section>
        <section>
          <a onClick={goToAnasayfa} style={{ cursor: "pointer" }}>
            Anasayfa
          </a>
          <a onClick={goToHakkimizda} style={{ cursor: "pointer" }}>
            Krumsal
          </a>
          <a onClick={goToUrunler} style={{ cursor: "pointer" }}>
            Ürünlerimiz
          </a>
          <a onClick={goToIletisim} style={{ cursor: "pointer" }}>
            İletişim
          </a>
        </section>
      </div>
      <div className="copyright">COPYRIGHT © CoffeeDean</div>
    </footer>
  );
};

export default Footer;
